.about h3,
.contact h3 {
  background-color: #4886a5;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: #fff;
  margin: 0 0 24px 26px;
  padding: 4px 0 4px 12px;
  position: relative;
  width: 100%;
}

.about h3:after,
.contact h3:after {
  border-color: #263746 transparent transparent #263746;
  border-style: solid;
  border-width: 6px;
  bottom: -12px;
  content: '';
  position: absolute;
  right: 0;
}

.about h3:before,
.contact h3:before {
  color: #fff;
  content: '';
  font: 26px sans-serif;
  position: absolute;
  right: 12px;
  top: -2px;
}

.circle {
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
}

p.contact-note {
  font-size: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.contact a {
  text-decoration: none;
}
